import { EventEmitter, Injectable } from '@angular/core';
import { NotificationHeader } from '@app/models/notification-header.model';
import { NotificationSV } from '@app/models/notification-sv.model';

@Injectable({
    providedIn: 'root',
})
export class ShareInfoService {
    acceptCookies$ = new EventEmitter<void>();

    tutorialResponse$ = new EventEmitter<number>();
    scrollGet$ = new EventEmitter<void>();

    snack$ = new EventEmitter<{ message: string; color: string }>();
    snackVicente$ = new EventEmitter<{
        message: string;
        type: 'error' | 'done';
    }>();
    tip$ = new EventEmitter<{ message: string; title_btn: string }>();

    socketUser$ = new EventEmitter<NotificationHeader>();
    notification$ = new EventEmitter<NotificationSV>();
    spinner$ = new EventEmitter<boolean>();

    specificFinalScroll$ = new EventEmitter<void>();
    refreshComponent$ = new EventEmitter<void>();
}
